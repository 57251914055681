body,
body * {
	font-family: "Bangers", cursive;
}

@mixin blkblug {
	color: #0078d7;
}

@mixin blkblbkg {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0015ff+0,0a0a0a+100&0.23+0,0.52+100 */
	background: -moz-linear-gradient(
		top,
		rgba(0, 21, 255, 0.23) 0%,
		rgba(10, 10, 10, 0.52) 100%
	);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(0, 21, 255, 0.23) 0%,
		rgba(10, 10, 10, 0.52) 100%
	);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(0, 21, 255, 0.23) 0%,
		rgba(10, 10, 10, 0.52) 100%
	);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b0015ff', endColorstr='#850a0a0a', GradientType=0);
	/* IE6-9 */
}

@-webkit-keyframes zoombg {
	0% {
		opacity: 0.1;
		background-size: 100%;
	}
	50% {
		opacity: 1;
		background-size: 130%;
	}
	100% {
		opacity: 0.1;
		background-size: 160%;
	}
}

@keyframes zoombg {
	0% {
		opacity: 0.1;
		background-size: 100%;
	}
	50% {
		opacity: 1;
		background-size: 130%;
	}
	100% {
		opacity: 0.1;
		background-size: 160%;
	}
}

@media (max-width: 768px) {
	.mainbox {
		min-height: 250px;
	}
	.mobilecrop {
		margin-left: 8px;
		margin-right: 8px;
	}
	.mobilecrop .projects {
		margin-bottom: 0;
	}
}

.Main {
	text-align: left;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	min-height: 645px;
}

.Main::after {
	content: "";
	background-image: url("./assets/bgridopacity.png");
	background-position: center center;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;
}

@media screen and (min-width: 1024px) {
	.Main::after {
		animation: zoombg 16s linear infinite;
		-webkit-animation: zoombg 16s linear infinite;
	}
}

.Main-header {
	background-color: #fff;
	padding: 40px 20px 20px;
	color: #000;
	border-bottom: 5px solid #ccc;
}

h1.Main-title {
	cursor: pointer;
	font-size: 1.8rem;
	text-decoration: none;
	a {
		@include blkblug;
		text-decoration: none;
	}
}

.title-subheading {
	font-family: "Oswald", sans-serif;
	font-size: 1em;
	font-weight: 300;
	letter-spacing: 1px;
}

.Main-title,
.mainbox h2 {
	font-size: 1.5em;
	@include blkblug;
}

.Main-header .Nav {
	display: inline-flex;
	text-align: right;
	@include blkblug;
	padding: 0;
	li {
		display: inline-block;
		font-family: "Bangers", cursive;
		padding: 0 8px 0 0;
	}
	li a {
		text-decoration: none;
		display: block;
		padding-top: 5px;
		border-bottom: 5px solid #fff;
		padding-bottom: 3px;
		font-size: 1.2rem;
		@include blkblug;
	}
	li a:hover {
		border-bottom: 5px solid #000000;
		cursor: pointer;
	}
}

.Main-intro {
	font-size: large;
}

.mainbox {
	min-height: 280px;
	margin: 40px 0;
	padding: 20px;
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #ccc;
	p {
		font-family: "Oswald", sans-serif;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: 1px;
	}
	a {
		@include blkblug;
	}
	.infoList {
		padding: 10px;
		margin: 10px;
	}
	.infoList li {
		font-size: 1.4em;
		list-style: none;
		padding-left: 8px;
		border-left: 3px solid #ccc;
		margin-bottom: 10px;
		font-family: "Oswald", sans-serif;
		font-size: 1em;
	}
}

@media (min-width: 992px) {
	.mainbox {
		min-height: 260px;
	}
}

.detailbox {
	margin: 40px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 20px;
	h1 {
		@include blkblug;
	}
	p {
		font-family: "Oswald", sans-serif;
		font-size: 1.1rem;
		font-weight: 300;
		letter-spacing: 1px;
	}
	a {
		@include blkblug;
	}
	.about-format {
		font-family: "Oswald", sans-serif;
		font-size: 1.5rem;
	}
	.projects-code > .row {
		margin-bottom: 20px;
		border-bottom: 1px solid #ccc;
		margin: 20px 10px;
		padding-bottom: 20px;
	}
	.projects-code p {
		font-size: 24px;
	}
	.projects-code img {
		border: 1px solid #ccc;
	}
	.projects-code a {
		display: block;
		cursor: pointer;
	}
}

.project-entry {
	padding-top: 20px;
	padding-bottom: 20px;
}

footer {
	border-top: 5px solid #ccc;
	padding: 20px 0;
	i {
		margin: 0 4px;
		font-size: 24px;
	}
	.fa-react {
		color: #00d8ff;
	}
}

.closex {
	cursor: pointer;
}

.white-bg {
	background-color: #fff;
}

.fa-html5 {
	color: #e34f26;
}

.fa-css3-alt {
	color: #e34f26;
}

.fa-js-square {
	color: #f7df1e;
}

.fa-node {
	color: #6cc24a;
}

.fa-database {
	color: #44883e;
}

.fa-apple {
	color: #000000;
}

.fa-windows {
	color: #0078d7;
}

@font-face {
	font-family: "Bangers";
	src: url("./assets/fonts/Bangers-Regular.woff2") format("woff2"),
		url("./assets/fonts/Bangers-Regular.woff") format("woff"),
		url("./assets/fonts/Bangers-Regular.svg") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Oswald";
	src: url("./assets/fonts/Oswald-Light.woff2") format("woff2"),
		url("./assets/fonts/Oswald-Light.woff") format("woff"),
		url("./assets/fonts/Oswald-Light.svg#Oswald-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Oswald";
	src: url("./assets/fonts/Oswald-Bold.woff2") format("woff2"),
		url("./assets/fonts/Oswald-Bold.woff") format("woff"),
		url("./assets/fonts/Oswald-Bold.svg#Oswald-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Oswald";
	src: url("./assets/fonts/Oswald-Regular.woff2") format("woff2"),
		url("./assets/fonts/Oswald-Regular.woff") format("woff"),
		url("./assets/fonts/Oswald-Regular.svg#Oswald-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	src: url("./assets/fonts/OpenSans-Italic.woff2") format("woff2"),
		url("./assets/fonts/OpenSans-Italic.woff") format("woff"),
		url("./assets/fonts/OpenSans-Italic.svg#OpenSans-Italic") format("svg");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	src: url("./assets/fonts/OpenSans.woff2") format("woff2"),
		url("./assets/fonts/OpenSans.woff") format("woff"),
		url("./assets/fonts/OpenSans.svg#OpenSans") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Open Sans";
	src: url("./assets/fonts/OpenSans-Light.woff2") format("woff2"),
		url("./assets/fonts/OpenSans-Light.woff") format("woff"),
		url("./assets/fonts/OpenSans-Light.svg#OpenSans-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
